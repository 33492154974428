<template>
  <v-main>
    <nav-bar />
    <template slot:top>
      <v-subheader>
        <h1>Réservations</h1>
      </v-subheader>
      <v-toolbar flat>
        <v-text-field
          class="pr-2 flex-grow-1"
          v-model="query"
          label="Mot clé"
          @input="search"
        />
        <v-select
          v-model="searchField"
          :items="searchFields"
          class="pl-2 flex-grow-0"
          dense
          solo
          @change="search"
        />
      </v-toolbar>
    </template>
    <v-data-table
      :headers="headers"
      :items="items"
      :server-items-length="itemsLength"
      :options.sync="options"
      :must-sort="true"
      :footer-props="{ 'items-per-page-options': [50] }"
      dense
    >
       <template v-slot:item.id="{ item }">
        <v-btn small class="info" @click="editBooking(item.uid)">
          {{ item.id }}
        </v-btn>
      </template>

      <template v-slot:item.rent="{ item }"> {{ item.rent }} € </template>

      <template v-slot:item.serviceCharges="{ item }">
        {{ item.serviceCharges }} €
      </template>
      <template v-slot:item.amount="{ item }">
        {{ item.amount }} {{ item.amount ? '€' : '' }}
      </template>

      <template v-slot:item.user="{ item }">
        <v-btn small class="info" @click="editRenter(item.userUid)">
          {{ item.user }}
        </v-btn>
      </template>

      <template v-slot:item.property="{ item }">
        <v-btn
          v-if="item.property !== null"
          small
          class="info"
          @click="editProperty(item.propertyUid)"
        >
          {{ item.property }}
        </v-btn>
      </template>
      <template v-slot:item.application="{ item }">
        <v-btn
          v-if="item.application !== null"
          small
          class="info"
          @click="editApplication(item.applicationUid)"
        >
          {{ item.application }}
        </v-btn>
      </template>

      <template v-slot:item.statusDate="{ item }">
        {{ item.status !== "1-Demandé 🕒" ? item.statusDate : "" }}
      </template>

      <template v-slot:item.refunded="{ item }">
        {{ item.refunded ? 'Oui 🔙' : '' }}
      </template>

      <template v-slot:item.actions="{ item }">
        <v-icon small target="_blank" @click="editBookingStatus(item.uid)">
          mdi-pencil
        </v-icon>
      </template>
    </v-data-table>
  </v-main>
</template>

<script>
import axios from "axios";
import navBar from "../components/navBar.vue";
export default {
  components: {
    navBar,
  },
  data() {
    return {
      headers: [
        { text: "Id", value: "id", sortable: true },
        { text: "Candidature", value: "application", sortable: true },
        { text: "Locataire", value: "user", sortable: true },
        { text: "Logement", value: "property", sortable: true },
        { text: "Loyer cc", value: "rent", sortable: true },
        { text: "Frais", value: "serviceCharges", sortable: true },
        { text: "Date de réservation", value: "creationDate", sortable: true },
        { text: "Date du status", value: "statusDate", sortable: true },
        { text: "Statuts", value: "status", sortable: false },
        { text: "Coupon", value: "coupon", sortable: true },
        { text: "Frais payés", value: "amount", sortable: true },
        { text: "Remboursement", value: "refunded", sortable: false },
        { text: "Actions", value: "actions", sortable: false },
      ],
      searchFields: [
        {
          text: "Dans toutes les colonnes",
          value: "id,property,status,application,user",
        },
        { text: "Locataire", value: "user" },
        { text: "Logement", value: "property" },
        { text: "Candidature", value: "application" },
        { text: "status", value: "status" },
      ],
      searchField: "id,user,application,property,refunded,status,creationDate,statusDate",
      items: [],
      itemsLength: 0,
      options: {
        sortBy: ["id","user","application","property","propertyServiceCharge","refunded","status","creationDate","statusDate"],
        sortDesc: [true],
        sortAsc: [true],
      },
      query: "",
    };
  },

  watch: {
    options: {
      handler() {
        this.getItems();
      },
      deep: true,
    },
  },

  methods: {
    getItems() {
      axios
        .get(process.env.VUE_APP_APIURL + "/bookings", {
          headers: {
            "Access-Token": localStorage.getItem("token"),
          },
          params: {
            offset: (this.options.page - 1) * this.options.itemsPerPage,
            limit: this.options.itemsPerPage,
            sort:
              this.options.sortBy[0] +
              " " +
              (this.options.sortDesc[0] ? "DESC" : "ASC"),
            searchQuery: this.query !== "" ? this.query : undefined,
            searchFields: this.searchField,
          },
        })
        .then((response) => {
          this.items = response.data.map(
            (item) =>
              Object.assign(
                item,
                { creationDate: new Date(
                  item.creationDate
                  ).toLocaleString() 
                },
                {
                  statusDate: new Date(
                    item.statusDate
                  ).toLocaleString(),
                },
                {
                  status: {
                    1: "1-Demandé 🕒",
                    2: "2-Payé ✅",
                    3: "3-Refusé ❌",
                    4: "4-Annulé",
                    5: "5-Accepté",
                  }[item.status],
                },
                {
                  rent:
                    item.propertyCharges +
                    item.propertyRent,
                },
                {
                  serviceCharges: item.propertyServiceCharge,
                }
              )
          );
        });
      axios
        .get(process.env.VUE_APP_APIURL + "/bookings", {
          headers: {
            "Access-Token": localStorage.getItem("token"),
          },
          params: {
            count: 1,
            searchQuery: this.query !== "" ? this.query : undefined,
            searchFields: this.searchField,
          },
        })
        .then((response) => {
          this.itemsLength = eval(response.data[0].count);
        });
    },

    search() {
      this.options.page = 1;
      this.getItems();
    },
    editBookingStatus(uid) {
      open(
        this.$router.resolve({ name: "editBooking", params: { bookingUid: uid } }).href,

        "_blank"
      );
    },
    editBooking(uid) {
      open(
        this.$router.resolve({ name: "booking", params: { bookingUid: uid } }).href,
        "_blank"
      );
    },
    editRenter(uid) {
      open(
        this.$router.resolve({ name: "user", params: { userUid: uid } }).href,
        "_blank"
      );
    },

    editProperty(uid) {
      open(
        this.$router.resolve({ name: "property", params: { propertyUid: uid } })
          .href,
        "_blank"
      );
    },

    editApplication(uid) {
      open(
        this.$router.resolve({
          name: "application",
          params: { applicationUid: uid },
        }).href,
        "_blank"
      );
    },
  },
};
</script>